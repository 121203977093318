<div class="gn-w-full gn-flex gn-items-start">
    <div class="gn-flex-1 gn-w-full gn-overflow-hidden">
        <form class="gn-w-full" [formGroup]="form">
            <div class="gn-w-full gn-flex-none md:gn-flex gn-items-start gn-mb-2">
                <div class="gn-w-full sm:gn-w-full md:gn-w-1/2 gn-mr-4" *ngIf="form.get('design').value">
                    <div style="height: 250px;" class="gn-w-full gn-relative gn-mb-2 gn-bg-white">
                        <img *ngIf="form.get('design').value" [src]="form.get('design').value" class="gn-absolute gn-top-0 gn-left-0 gn-object-cover gn-h-full gn-w-full gn-overflow-hidden" />
                        <div class="gn-h-full gn-w-full gn-flex gn-flex-col gn-relative gn-z-10">
                            <div class="gn-w-full gn-flex-1 gn-relative">
                                <img *ngIf="company.logo && (+company.company_id !=2  || needsLogo(form.get('design').value))" [src]="company.logo" (error)="changePath()" class="gn-h-16 gn-w-48 gn-object-left-top  gn-object-contain gn-mt-2 gn-ml-2" />
                            </div>
                            <div class="gn-w-full gn-flex-1 gn-text-white gn-flex gn-items-center gn-justify-center gn-flex-col gn-relative">
                                <p class="gn-mb-2 gn-font-bold gn-text-white gn-outline" *ngIf="!form.get('preset').value">
                                    <span *ngIf="form.get('title').value" class="gn-outline">{{ form.get('title').value }}</span>
                                    <span *ngIf="!form.get('title').value" class="gn-outline">{{ currencySymbol }}{{ form.get('value').value }} Gift Voucher</span>
                                </p>
                                <p class="gn-mb-2 gn-font-bold gn-text-white gn-outline" *ngIf="form.get('preset').value">{{ form.get('preset').value.name }}</p>
                                <p class="gn-mb-1 gn-text-sm gn-font-bold gn-text-white gn-outline" *ngIf="form.get('name').value">To {{ form.get('name').value }}</p>
                                <p class="gn-text-sm gn-text-white gn-font-bold gn-text-center gn-px-2 gn-outline" *ngIf="form.get('message').value">{{ form.get('message').value }}</p>
                            </div>
                            <div class="gn-w-full gn-flex-1 gn-flex gn-items-end gn-justify-between gn-pb-5 gn-px-5 gn-relative redemptioncode">
                                <div class="gn-w-40 gn-h-12 gn-bg-white gn-rounded-lg gn-text-sm gn-flex gn-flex-col gn-items-center gn-justify-center">
                                    <p class="gn-text-xs gn-text-gray-700 gn-leading-none mb-1 rcode2">REDEMPTION CODE</p>
                                    <p class="gn-text-xs gn-text-gray-700 gn-leading-none rcode2">000000-0000000</p>
                                </div>
                                <div class="gn-w-40 gn-h-12 gn-bg-white gn-rounded-lg gn-text-sm gn-flex gn-flex-col gn-items-center gn-justify-center">
                                    <p class="gn-text-xs gn-text-gray-700 gn-leading-none gn-mb-1 rcode2">EXPIRY DATE</p>
                                    <p class="gn-text-xs gn-text-gray-700 gn-leading-none rcode2">{{ expiry }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <swiper-container slides-per-view="3.7" space-between="10"  class="gn-overflow-auto gn-mb-2"
                    *ngIf="!form.get('preset').value && !form.get('fixed_image').value" >
                        
                        <swiper-slide
                            *ngFor="let item of filteredImages;"
                            (click)="form.get('design').setValue(item.full_url);form.get('image').setValue(item.full_url); setHistory()"
                        >
                            <img class="gn-object-cover gn-overflow-hidden gn-h-16 sm:gn-h-20" [src]="item.full_url" />
                        </swiper-slide>
                    </swiper-container>
                </div>
                <div class="agn-flex-1 agn-relative">
                    <div class="gn-w-full gn-flex gn-items-center gn-justify-between gn-mb-2">
                        <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                            <div class="gn-flex gn-border gn-border-gray-500 gn-rounded gn-overflow-hidden">
                                <button type="button" *ngIf="!form.get('preset').value && !form.get('title').value" (click)="editVoucherValue(-company.voucher_config.step)" class="gn-w-4 md:gn-w-8 gn-px-1 sm:gn-px-2 lg:gn-px-3 gn-text-lg gn-text-gray-700 gn-font-medium gn-bg-gray-200 hover:gn-bg-gray-400 gn-flex gn-items-center gn-justify-center">
                                    <span>-</span>
                                </button>
                                <input formControlName="value" [prefix]="currencySymbol" mask="0*.00" class="input gn-bg-white gn-text-center gn-rounded-none gn-w-24 sm:gn-w-24 lg:gn-w-28 gn-border-0" type="text" placeholder="Amount" />
                                <button type="button" *ngIf="!form.get('preset').value && !form.get('title').value" (click)="editVoucherValue(company.voucher_config.step)" class="gn-w-4  md:gn-w-8 gn-px-1 sm:gn-px-2 lg:gn-px-3 gn-text-lg gn-text-gray-700 gn-font-medium gn-bg-gray-200 hover:gn-bg-gray-400 gn-flex gn-items-center gn-justify-center">
                                    <span>+</span>
                                </button>
                            </div>
                        </div>
                        <button (click)="deleted.emit()" type="button" class="gn-w-5 gn-h-5 gn-bg-red-500 hover:gn-bg-red-700 gn-rounded-full gn-flex gn-items-center gn-justify-center gn-text-white gn-relative gn-right-4 sm:gn-right-0">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                    </div>


                    <ng-container *ngIf="wrapOptions.length">
                        <p class="gn-text-gray-700 gn-text-xs gn-uppercase gn-mb-1">{{ 'voucher.wrapping' | translate }}</p>
                        <div class="gn-w-full gn-mb-2">
                            <ng-select
                                bindLabel="name"
                                bindValue="wrap_option_id"
                                formControlName="wrap_option_id"
                                [items]="wrapOptions"
                                [clearable]="false"
                                (change)="setWrapOption($event)"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="gn-w-full gn-flex gn-items-center">
                                        <p class="gn-text-gray-700 gn-mr-2">{{ item.name }}</p>
                                        <p class="gn-text-gray-500" *ngIf="item.price !== '0.00'">({{ item.price | currency: currency:'symbol-narrow' }}) </p>
                                        <p class="gn-text-gray-500" *ngIf="item.price === '0.00'">({{ 0 | currency: currency:'symbol-narrow' }})</p>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="gn-w-full gn-flex gn-items-center gn-justify-between">
                                        <p class="gn-text-gray-700">{{ item.name }}</p>
                                        <p class="gn-text-gray-500" *ngIf="item.price !== '0.00'">{{ item.price | currency: currency:'symbol-narrow' }}</p>
                                        <p class="gn-text-gray-500" *ngIf="item.price === '0.00'">{{ 0 | currency: currency:'symbol-narrow' }}</p>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>
                    <input formControlName="name" class="input gn-w-full gn-mb-2 gn-mb-2" type="text" [placeholder]="'voucher.recipient_name' | translate" />
                    <textarea formControlName="message" class="input gn-w-full" rows="5" [placeholder]="'voucher.personalised_message' | translate" maxlength="200"></textarea>
                    <span class="gn-text-red-500" *ngIf="form.get('message').value?.length >= 200">Message cannot be longer than 200 chars</span>
                </div>
            </div>
            <ng-container *ngIf="form.get('tag').value !== 'email'">
                <p class="gn-text-gray-700 gn-text-xs gn-uppercase gn-mb-1">{{ 'voucher.delivery' | translate }}</p>
                <div class="gn-w-full gn-grid gn-grid-1 sm:gn-flex gn-mb-2">
                    <div class="gn-w-full gn-mr-1">
                        <ng-select
                            formControlName="mail_service_id"
                            bindLabel="name"
                            bindValue="mail_service_id"
                            [items]="mailServices"
                            [clearable]="false"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                <div class="gn-w-full gn-flex gn-items-center">
                                    <p class="gn-text-gray-700 gn-mr-2">{{ item.name }}</p>
                                    <p class="gn-text-gray-500" *ngIf="item.price !== '0.00'">({{ item.price | currency: item.currency:'symbol-narrow' }})</p>
                                    <p class="gn-text-gray-500" *ngIf="item.price === '0.00'">({{ 0 | currency: item.currency:'symbol-narrow' }})</p>
                                    <p class="gn-text-gray-500"> - {{ item.notes }}</p>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <div class="gn-w-full gn-flex gn-items-center gn-justify-between">
                                    <p class="gn-text-gray-700">{{ item.name }}</p>
                                    <p class="gn-text-gray-500" *ngIf="item.price !== '0.00'">{{ item.price | currency: item.currency:'symbol-narrow' }}</p>
                                    <p class="gn-text-gray-500" *ngIf="item.price === '0.00'">{{ 0 | currency: item.currency:'symbol-narrow' }}</p>
                                    <p class="gn-text-gray-500"> {{ item.notes }}</p>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="gn-w-full gn-ml-1">
                        <button type="button" *ngIf="vouchers.length > 1 && index > 0" (click)="copyAddress()" class="gn-text-sm gn-h-full gn-w-full gn-text-center gn-bg-gray-700 gn-text-white gn-rounded gn-font-medium">Same Address as Above</button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="form.get('mail_service_id').value !== '1'">
                <div class="gn-w-full gn-flex gn-mb-2 gn-mt-2">
                    <div class="gn-w-full">
                        <input formControlName="delivery_name" class="input gn-w-full" type="text" [placeholder]="'Delivery Name' | translate" />
                    </div>
                </div>
                <div class="gn-w-full gn-flex gn-mb-2 gn-mt-2">
                    <div class="gn-w-full">
                        <input formControlName="address_1" class="input gn-w-full" type="text" [placeholder]="'Address 1' | translate" />
                    </div>
                </div>
                <div class="gn-w-full gn-flex gn-mb-2">
                    <div class="gn-w-full">
                        <input formControlName="address_2" class="input gn-w-full" type="text" [placeholder]="'Address 2 (Optional)' | translate" />
                    </div>
                </div>
                <div class="gn-w-full gn-flex">
                    <div class="gn-w-1/2 gn-pr-1">
                        <input formControlName="town" class="input gn-w-full" type="text" [placeholder]="'Town / City' | translate" />
                    </div>
                    <div class="gn-w-1/2 gn-pl-1">
                        <input formControlName="zip" class="input gn-w-full" type="text" [placeholder]="'ZIP / Post Code' | translate" />
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>

<style>
@media only screen and (max-width: 550px) {
.redemptioncode .rcode2{
  font-size: 10px!important;;
  }
}</style>
