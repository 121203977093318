import { Component, Input, Output, EventEmitter, OnInit, ViewChildren, QueryList } from '@angular/core';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { getCurrencySymbol } from '@angular/common';

import { PaymentService } from 'src/app/services/payment.service';
import { VoucherComponent } from '../voucher/voucher.component';

@Component({
    selector: 'app-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.sass']
})
export class BasketComponent implements OnInit {
    @ViewChildren('voucherEl') voucherRefs: QueryList<VoucherComponent>;

    @Output() deleted: EventEmitter<number> = new EventEmitter();
    @Output() added: EventEmitter<any> = new EventEmitter();
    @Output() pay: EventEmitter<void> = new EventEmitter();

    @Input() form: UntypedFormArray;
    @Input() company: any;
    @Input() totalVouchers = 0;
    @Input() totalWrapping = 0;
    @Input() totalDelivery = 0;
    @Input() total = 0;
    @Input() brandId: string = '';

    public faGiftCard = faGift;

    public confirmMissingPersonalization :boolean = false;
    public missingPersonalization :boolean = false;
    public ignoreMissingPersonalization :boolean = false;

    public amount = new UntypedFormControl(25);
    public currencies = ['GBP','EUR','USD','AUD','NZD','ZAR']

    public newCurrency: string;
    public showCurrency: boolean = false;

    constructor(
        private payment: PaymentService
    ) { }

    ngOnInit() {
        this.amount.disable();

        this.amount.setValue(this.company?.voucher_config?.displayValue || 25);

        this.newCurrency = this.company?.currency;
    }

    public get acceptPayments(): boolean {
        return this.payment.initialized;
    }

    public get currency(): string {
        return getCurrencySymbol(this.company?.currency || 'USD', 'narrow', 'en-GB');
    }

    public addVoucher(): void {
        this.added.emit({ value: this.amount.value, wrap_option_id: '3', mail_service_id: '1' });
    }

    public editVoucherValue(value: number): void {
        const val = this.amount.value + value;

        if (val <= this.company?.voucher_config?.max && val >= this.company?.voucher_config?.min) this.amount.setValue(val);
    }

    public validate(): void {
        const invalid = this.voucherRefs.filter(item => {
            item.form.markAllAsTouched();

            return item.form.invalid;
        });

        if (invalid.length > 0) return;

        //check if 

        this.missingPersonalization = false;

        if(!this.ignoreMissingPersonalization){
            this.voucherRefs.forEach(it=>{
                let vals = it.form.getRawValue();
                if(!vals.message || !vals.name)
                    this.missingPersonalization = true;

            })
        }
        if(this.missingPersonalization) {
            if(!this.confirmMissingPersonalization) {
                this.confirmMissingPersonalization = true;
                return;
            }
        }

        this.pay.emit();
    }

    public changeCurrency(event): void{
        if(! event) return;
        let el : any= document.getElementsByTagName('geronigo-vouchers');
        if(el.length == 0){
            el = document.getElementsByTagName('rezbot-vouchers');
        }

        if(el.length > 0){
            console.log(el[0]);
            el[0].setCurrency =event
        }


    }
}
